<template>
  <div>
    <PageHeader>
      <div class="text-gray-900 uppercase text-2xl font-semibold py-2">
        Rapports des ventes par article
      </div>
    </PageHeader>
    <div class="p-6">
      <div class="flex justify-end items-center">

        <el-select
          v-model="typeSearch"
          class="mr-2"
          placeholder="Select"
          @change="HANDLE_PLAGE"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-date-picker
          v-model="listQuery.start"
          type="date"
          value-format="yyyy-MM-dd"
          format="dd MMM yyyy"
          placeholder="Date debut"
        >
        </el-date-picker>
        <span class="mx-2">Au</span>
        <el-date-picker
          class="mr-2"
          v-model="listQuery.end"
          type="date"
          value-format="yyyy-MM-dd"
          format="dd MMM yyyy"
          placeholder="Date debut"
        ></el-date-picker>
        <el-select
          class="mr-2"
          v-model="listQuery.saleagent"
          filterable
          placeholder="Agent commercial"
          clearable
          @change="INIT_DATA"
        >
          <el-option
            v-for="item in employees"
            :key="item.number"
            :label="item.full_name"
            :value="item.number"
          >
          </el-option>
        </el-select>
        <input-client class="mr-2  flex-1" @customer="handleCustomer" />
        <el-button
          @click="INIT_DATA"
          class="ml-2"
          icon="el-icon-search"
          :disabled="btnSeachValid"
        >
          Rechercher
        </el-button>
        <el-dropdown @command="HANDLE_EXPORT">
          <el-button class="ml-2" :loading="exportLoanding">
            Exporter
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="PDF">En PDF </el-dropdown-item>
            <el-dropdown-item command="EXCEL">En EXCEL</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div v-loading="listLoading">

        <div class="p-4 my-5 bg-white shadow">
          <el-tabs v-model="activeName" @tab-click="handleTab">
            <el-tab-pane label="TOUTE" name="ALL"></el-tab-pane>
            <el-tab-pane label="FACTURATION" name="INVOICE"></el-tab-pane>
            <el-tab-pane label="POINT DE VENTE" name="TICKET_POS"></el-tab-pane>
          </el-tabs>
          <div class="flex justify-end"></div>
          <div class="customer-white">
            <div class="flex justify-around items-center mb-5">
              <div class="mr-10 text-center">
                <div class="text-xl font-semibold">
                  {{ listQuery.total }}
                </div>
                <div class="text-gray-400 my-2">
                  <span>Unités vendus</span>

                  <!--    <span v-if="listQuery.type == 'CUSTOMER'">Nombre de facture</span> -->
                </div>
              </div>

              <div class="mr-10 text-center">
                <div class="text-xl font-semibold">
                  {{ resume.total_invoice | moneyFilter }} {{user.currency_code}}
                </div>
                <div class="text-gray-400 my-2">Chiffre d'affaire</div>
              </div>

              <div class="mr-10 text-center">
                <div class="text-xl font-semibold">
                  {{ resume.cost_purchase | moneyFilter }} {{user.currency_code}}
                </div>
                <div class="text-gray-400 my-2">Coût d'achat </div>
              </div>

              <div class="text-center">
                <div class="text-xl font-bold">
                  {{ resume.profit | moneyFilter }} {{user.currency_code}}
                </div>
                <div class="text-gray-400 my-2">Bénéfice brut</div>
              </div>
            </div>
          </div>

          <el-table :data="items" v-loading="listLoading" style="width: 100%">
            <el-table-column label="CODE" width="140">
              <template slot-scope="{ row }">
                <span class="text-blue">{{ row.reference }}</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="purchaseorder_date"
              label="DÉSIGNATION"
              min-width="200"
            >
              <template slot-scope="{ row }">
                <span>{{ row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Quantité" align="center" width="110">
              <template slot-scope="{ row }">
                <span class="">{{ row.quantity }} </span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="TOTAL TTC" width="170">
              <template slot-scope="{ row }">
                <span class="">{{ row.total_invoice | moneyFilter }} </span>
              </template>
            </el-table-column>
            <el-table-column label="Coût des article" width="160" align="right">
              <template slot-scope="{ row }">
                <span class="">{{ row.cost_purchase | moneyFilter }} </span>
              </template>
            </el-table-column>
            <!--   <el-table-column
              prop="total_all_taxe"
              label="C.A FACT "
              width="140"
              align="right"
            >
              <template slot-scope="{ row }">
                <span class="">{{ row.total_invoice | moneyFilter }} </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="total_all_taxe"
              label="C.A PDV"
              width="140"
              align="right"
            >
              <template slot-scope="{ row }">
                <span class="">{{ row.total_ticket | moneyFilter }} </span>
              </template>
            </el-table-column> 
            <el-table-column
              prop="total_all_taxe"
              label="C.A GLOBAL"
              width="140"
              align="right"
            >
              <template slot-scope="{ row }">
                <span class=""
                  >{{ (row.total_invoice + row.total_ticket) | moneyFilter }}
                </span>
              </template>
            </el-table-column>-->
            <el-table-column label="BÉNÉFICE" align="right" width="160">
              <template slot-scope="{ row }">
                <span>{{ row.profit | moneyFilter }} </span>
              </template>
            </el-table-column>

            <el-table-column label="MARGE" align="center" width="140">
              <template slot-scope="{ row }">
                <span>{{ row.marge }} </span>
              </template>
            </el-table-column>
          </el-table>
          <div class="flex justify-end">
            <pagination
              v-show="listQuery.total > 0"
              :total="listQuery.total"
              :page.sync="listQuery.page"
              :limit.sync="listQuery.size"
              @pagination="GET_FETCH_RAPPORT()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { toThousandFilter } from "@/Filters";
//import ChartSale from "./chart.vue";

import {
  parseDate,
  getDay,
  getStartOfWeek,
  getEndOfWeek,
  getStartOfMonth,
  getEndOfMonth,
  getStartOfYear,
  getEndOfYear,
} from "@/utils";
import {
  fetchRapportSalesArticle,
  fetchRapportResume
} from "@/api/sales2";
import { getEmployeesList } from "@/api/employee";
import { exportRapportSale, exportExcelRapportSale } from "@/api/export";

import PageHeader from "@/components/PageHeader";
import {mapGetters} from "vuex";

import inputClient from "@components/searchCustomer/index.vue";
export default {
  name: "Rapport-Sales",

  components: {Pagination, inputClient, /* ChartSale, Article,*/ PageHeader },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },

  data() {
    return {
      typeSearch: "week",
      exportLoanding: false,
      showChart: false,
      activeName: "ALL",
      employees: [],
      customers: [],

      options: [
        {
          label: "Journalier",
          value: "day",
        },
        {
          label: "Hebdomadaire",
          value: "week",
        },
        {
          label: "Mensuel",
          value: "month",
        },
        {
          label: "Annuel",
          value: "year",
        },
      ],
      chartCategories: [],
      chartSeries: [],

      resume: {
        total_invoice: 0,
        cost_purchase: 0,
        count_item: 0,
        profit: 0,
      },
      listLoading: false,
      items: [],
      typeData: [
        { label: "Par clients", value: "CUSTOMER" },
        { label: "Par articles", value: "ARTICLE" },
      ],
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "name",
        start: getStartOfWeek(),
        end: getEndOfWeek(),
        user: undefined,
        type: undefined,
        saleagent: undefined,
        contactID: undefined,
      },
    };
  },
  computed: {
      ...mapGetters(["user"]),
    btnSeachValid() {
      if (this.listQuery.start !== null && this.listQuery.end !== null) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.fetchEmployee();
    this.INIT_DATA();
  },
  methods: {
    async fetchEmployee() {
      await getEmployeesList("SALES_AGENT")
        .then((res) => {
          this.employees = res.data;
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "danger",
          });
        });
    },
    async GET_FETCH_RAPPORT() {
      this.listLoading = true;

      await fetchRapportSalesArticle(this.listQuery).then((res) => {
        setTimeout(() => {
          let revenues = [];
          let profits = [];
          let categories = [];
          const data = res.data.items;
          this.items = data;

          this.listQuery.total = parseInt(res.data.total_items);
          this.listQuery.page = parseInt(res.data.current_page) + 1;
          data.map((el) => {
            revenues.push(el.total_invoice + el.total_ticket);
            profits.push(el.profit);
            categories.push(el.name);
          });
          // vide
          this.chartCategories = [];
          this.chartSeries = [];
          this.chartCategories = categories;
          this.chartSeries.push(
            {
              name: "C.A",
              type: "column",
              data: revenues,
            },
            {
              name: "M.BÉNÉFICAIRE",
              type: "column",
              data: profits,
            }
          );
          console.log(this.chartSeries);

          this.listLoading = false;
        }, 1.5 * 1000);
      });
    },

    async GET_FETCH_RAPPORT_RESUME() {
      this.listLoading = true;

      await fetchRapportResume(this.listQuery).then((res) => {
        setTimeout(() => {
          this.resume.total_invoice = res.data.total_invoice;
          this.resume.cost_purchase = res.data.cost_purchase;
          this.resume.profit = res.data.profit;
          this.resume.count_item = res.data.count_item;
        }, 1.5 * 1000);
      });
    },

    INIT_DATA() {
      if (this.listQuery.start !== null && this.listQuery.end !== null) {
        if (
          this.listQuery.saleagent === "" ||
          this.listQuery.saleagent === null
        ) {
          this.listQuery.saleagent = undefined;
        }
        this.GET_FETCH_RAPPORT();
        this.GET_FETCH_RAPPORT_RESUME();
      }
    },

    HANDLE_PLAGE(e) {
      if (e === "day") {
        this.listQuery.start = getDay();
        this.listQuery.end = getDay();
      }
      if (e === "week") {
        this.listQuery.start = getStartOfWeek();
        this.listQuery.end = getEndOfWeek();
      }
      if (e === "month") {
        this.listQuery.start = getStartOfMonth();
        this.listQuery.end = getEndOfMonth();
      }
      if (e === "year") {
        this.listQuery.start = getStartOfYear();
        this.listQuery.end = getEndOfYear();
      }
      this.INIT_DATA();
    },

    exportRapportSalesPDF() {
      this.exportLoanding = true;
      if (this.listQuery.saleagent == "" || this.listQuery.saleagent === null) {
        this.listQuery.saleagent = undefined;
      }
      exportRapportSale(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute(
              "download",
              "rapporte_vente_du" +
                this.listQuery.start +
                "_" +
                this.listQuery.end +
                ".pdf"
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.exportLoanding = false;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    exportRapportSalesExcel() {
      this.exportLoading = true;
      if (this.listQuery.saleagent == "" || this.listQuery.saleagent === null) {
        this.listQuery.saleagent = undefined;
      }
      exportExcelRapportSale(this.listQuery)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            this.listQuery.end + "_" + this.listQuery.end + "rapport_vente.xls"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.exportLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    HANDLE_TYPE_RAPPORT() {
      this.INIT_DATA();
    },
    HANDLE_EXPORT(command) {
      switch (command) {
        case "PDF":
          this.exportRapportSalesPDF();
          break;
        case "EXCEL":
          this.exportRapportSalesExcel();
          break;
      }
    },
    handleShowChart() {
      this.showChart = !this.showChart;
    },
    handleTab(obj) {
      const name = obj.name;

      switch (name) {
        case "INVOICE":
          this.listQuery.type = "INVOICE";
          break;
        case "TICKET_POS":
          this.listQuery.type = "TICKET_POS";
          break;

        default:
          this.listQuery.type = undefined;
      }
      this.INIT_DATA();
    },
      handleCustomer(id) {
        if (id!==""){
           this.listQuery.contactID = id;
         }
          this.INIT_DATA();
      },
  },
};
</script>

<style lang="scss" scoped></style>
